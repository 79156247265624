import React from "react";
// import { Link } from "react-router-dom";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Footer from "pages/LandingPages/Author/sections/Footer";
import routes from "routes";
import bgImage from "assets/images/tmf.webp";
import Pages from "pages/Presentation/sections/Pages";
// import Container from "@mui/material/Container";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Grid from "@mui/material/Grid";
import foto from "assets/images/tmfblog1.webp";
import { Helmet } from "react-helmet";
import additionalImage from "assets/images/tmfblog1.webp";

function SpecialComponent() {
  return (
    <>
      <Helmet>
        <title>Azərbaycanda ilk dəfə Təhsil Menecerləri Forumu keçiriləcək</title>
        <meta
          name="description"
          content="Noyabrın 19-da yerli və xarici təhsil müəssisələri rəhbərlərinin iştirakı ilə 20 nömrəli məktəbdə Təhsil Menecerləri Forumu keçiriləcək."
        />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/qeydiyyat",
          label: "qeydiyyatdan keç",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="11rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <MKBox display="flex" justifyContent="center" p={3}>
          <Grid container item xs={12} md={12} justifyContent="center">
            <MKBox mb={5} sx={{ maxWidth: "100%" }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={6}>
                  <img
                    src={additionalImage}
                    alt="Additional"
                    style={{ width: "90%", borderRadius: "8px" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    image={foto}
                    title="Azərbaycanda ilk dəfə Təhsil Menecerləri Forumu keçiriləcək"
                    description={
                      <MKBox sx={{ textAlign: "justify" }}>
                        Noyabrın 19-da yerli və xarici təhsil müəssisələri rəhbərlərinin iştirakı
                        ilə 20 nömrəli məktəbdə Təhsil Menecerləri Forumu keçiriləcək. Hədəf
                        Şirkətlər Qrupundan AZƏRTAC-a bildirilib ki, forumda Hədəf STEAM Liseyinin
                        qurucusu Şəmil Sadiq, Kipr Universitetlər Birliyinin rəhbəri Salman
                        Arslanbaş, Milli Məclisin deputatı Ceyhun Məmmədov, professor Hamlet
                        İsaxanlı, “Azərbaycan İnsan Resursları Assosiasiyası” İctimai Birliyinin
                        təsisçisi Nərgiz Seyidzadə, Dövlət Gömrük Komitəsi Akademiyasının rəisi Qulu
                        Novruzov, “CIBS Europe” təlim mərkəzinin təsisçisi Təbriz Hacınski, Bakı
                        Şəhəri üzrə Təhsil İdarəsində Keyfiyyətə nəzarət sektorunun müdiri Anar
                        Mustafazadə, biznes məsləhətçisi Fəxri Ağayev, tarix üzrə fəlsəfə doktoru
                        Elvin Əliyev, təlimçi Fərid Seyfullayev, 291 nömrəli Ekologiya liseyinin
                        direktoru Arif Əsədov çıxış edəcəklər. Jurnalist Orxan Cabbarlının
                        moderatorluğu ilə “Uğurlu təhsil konsepsiyaları”, “Təhsildə idarəetmənin
                        strategiyası”, “Məktəb idarəetməsindəki yanlışlıqlar”, “Yeni nəslə yeni
                        təhsil”, “Təhsilin onlayn idarəedilməsi və rəqəmsallaşma”, “Metamən”
                        mövzularında panel müzakirələri aparılacaq, təhsil sistemi və xidmətlərinin
                        effektiv təşkili, kadrların idarə olunması, təhsildə informasiya
                        texnologiyalarının tətbiqi və istifadəsi, keyfiyyətli tədris, yeni dünyaya
                        inteqrasiya istiqamətində doğru bildiyimiz yanlışları müəyyənləşdirmək,
                        dövlət və özəl təhsil müəssisələri arasında işgüzar əlaqələrin və
                        kommunikasiyanın daha da möhkəmləndirilməsi kimi aktual məsələlərə
                        toxunulacaq. Təhsil forumu direktorlar, direktor müavinləri, müəllimlər və
                        başqa bütün təhsillə maraqlananlar üçün nəzərdə tutulub. Sonda bütün
                        iştirakçılara sertifikat veriləcək.
                      </MKBox>
                    }
                  />
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </MKBox>
        <Pages />
        <Footer />
      </MKBox>
    </>
  );
}

export default SpecialComponent;
