import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// TMF React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// TMF React routes
import routes from "routes";
import SpecialComponent from "pages/LandingPages/Blog/news/newpage";
import Blogone from "pages/LandingPages/Blog/news/blog1";
import BlogTwo from "pages/LandingPages/Blog/news/blogtwo";
import Contact from "pages/LandingPages/Author/sections/Contact";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="contact" element={<Contact />} />
        <Route path="/menecerlerin-forumu-kecirilecek" element={<SpecialComponent />} />
        <Route path="/menecerlerin-forumu-kecirildi" element={<Blogone />} />
        <Route path="/menecerlerin-forumu-kecirilib" element={<BlogTwo />} />
      </Routes>
    </ThemeProvider>
  );
}
