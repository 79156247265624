import team1 from "assets/images/tehsil-menecerleri-forumu.webp";
import team2 from "assets/images/tehsilmenecerleriforumu.webp";
import team3 from "assets/images/tmfblog1.webp";
import "./style.css";
// import team2 from "assets/images/bg2.webp";
export default [
  {
    image: team1,
    name: "Azərbaycanda ilk: Təhsil Menecerləri Forumu keçirilib",
    route: "/menecerlerin-forumu-kecirilib",
    className: "custom-image-class",
  },
  {
    image: team2,
    name: "Təhsil Menecerlərinin Forumu keçirilmişdir",
    route: "/menecerlerin-forumu-kecirildi",
    className: "custom-image-class",
  },
  {
    // image: `${imagesPrefix}/team-1.webp`,
    image: team3,
    name: "Azərbaycanda ilk dəfə Təhsil Menecerləri Forumu keçiriləcək",
    route: "/menecerlerin-forumu-kecirilecek",
    className: "custom-image-class",
  },
];
